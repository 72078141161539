const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/home/",
  }, {
    path: "/home/",
    name: "home",
    asyncComponent: () => import("../views/home.vue"),
  }, {
    path: "/cmbBank/",
    name: "cmbBank",
    asyncComponent: () => import("../views/cmbBank.vue"),
  }, {
    path: "/citicBank/",
    name: "citicBank",
    asyncComponent: () => import("../views/citicBank.vue"),
  }, {
    path: "/msBank/",
    name: "msBank",
    asyncComponent: () => import("../views/msBank.vue"),
  }, {
    path: "/about/",
    name: "msBank",
    asyncComponent: () => import("../views/about.vue"),
  }, {
    path: "/FAQ/",
    name: "FAQ",
    asyncComponent: () => import("../views/citicFAQ.vue"),
  }, {
    path: "/gfBank/",
    name: "gfBank",
    asyncComponent: () => import("../views/gfBank.vue"),
  },
  
  // Default route, match to all pages (e.g. 404 page)
  {
    path: "(.*)",
    asyncComponent: () => import("../views/notfound.vue"),
  },
];


export default routes;
