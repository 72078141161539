import { createApp } from 'vue'
import App from './App.vue'

import Framework7 from "./f7-config/f7-custom";
import Framework7Vue, { registerComponents } from "framework7-vue/bundle";
import "framework7-icons";

Framework7.use(Framework7Vue);


var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?ae93bdeede8539fcc8f2ccd6bcf6ca80";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();


const app = createApp(App);
registerComponents(app);

app.mount('#app')
