<template>
  <f7-app v-bind="f7Params">
    <f7-view
      main
      url="/"
      :stackPages="true"
      :browser-history="true"
      browserHistorySeparator="#"
      class="safe-areas"
    ></f7-view>
  </f7-app>
</template>

<script>
import routes from "@/routes";
import { onMounted } from "vue";
import { getDevice } from "./f7-config/f7-custom";
export default {
  name: "App",
  components: {},
  setup() {
    const device = getDevice();

    const f7Params = {
      name: "东航信贷平台", // App name
      theme: "ios", // Automatic theme detection
      id: "io.cescfc.xingfuApp", // App bundle ID
      // App routes
      routes: routes,
      touch: {
        iosTouchRipple: false,
      },

      // Input settings
      input: {
        scrollIntoViewOnFocus: device.capacitor,
        scrollIntoViewCentered: device.capacitor,
      },
      // Capacitor Statusbar settings
      statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: true,
      },
    };

    // const f7Ready = () => {
      
    // };

    onMounted(() => {
      // f7ready(f7Ready);
    });

    return {
      f7Params,
    };
  },
};
</script>

<style lang="less">
@import "styles/app";
@import "styles/f7-custom";
:root {
    --f7-theme-color: #BE0017;
    --f7-theme-color-rgb: 190,0,23;
    --f7-theme-color-shade: #BE0017;
    --f7-theme-color-tint: #BE0017;
    --f7-theme-color-text-color: #BE0017; 
    --f7-safe-area-left: 0px;
    --f7-safe-area-right: 0px;
    --f7-safe-area-top: 0px;
    --f7-safe-area-bottom: 0px;
    --f7-safe-area-outer-left: 0px;
    --f7-safe-area-outer-right: 0px;
    --f7-device-pixel-ratio: 1;
}
</style>
